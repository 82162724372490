import { Web3Provider } from '@ethersproject/providers';
import { formatEther } from '@ethersproject/units';
import { markRaw } from 'vue';
import { mixins, Options } from 'vue-class-component';
import { EthereumProvider } from '@manifoldxyz/frontend-provider-core';
import {
  ADDRESS_CHANGED,
  CHAIN_CHANGED,
  EthereumNetwork,
  PROVIDER_CHANGED
} from '@manifoldxyz/frontend-provider-types';
import { detectProvider } from '@manifoldxyz/studio-app-sdk';
import {
  AbstractProvider,
  ETHEREUM_NETWORK_COLORS,
  InjectedProvider,
  REAUTHENTICATE
} from '@/common/constants';
import web3TransactionErrorHandling, {
  TransactionError,
  TransactionErrors
} from '@/common/web3TransactionErrorHandling';
import { MConnectInjectPropsMixin } from '@/exports/MConnectProps';

enum BrowserWallets {
  CoinbaseWallet = 'Coinbase Wallet',
  MetaMask = 'MetaMask',
  Bitski = 'Bitski',
  Brave = 'Brave',
  LedgerConnect = 'Ledger Connect'
}

interface BrowserWallet {
  name: string;
  network: number | undefined;
  provider?: InjectedProvider;
  logo?: string;
}

@Options({
  watch: {
    walletAddressFull: async function () {
      await this.updateBalance();
    }
  }
})
export default class WalletMixin extends mixins(MConnectInjectPropsMixin) {
  // variables that are not injected and defined only here
  badConfiguration: string | null | undefined = null;
  providerAvailable = false;
  walletAddressFull: string | undefined = '';
  walletAddressShort: string | undefined = '';
  walletENS: string | undefined = '';
  walletAvailable = !!(window && window.ethereum);
  walletBalance: string | undefined = '';
  walletConnected = false;
  walletsHidden: Set<string> = new Set();
  wrongChain = false;
  isLoading = false;
  chainInfo: {
    name: string;
    color: typeof ETHEREUM_NETWORK_COLORS;
  } | null = null;
  browserWallets: BrowserWallet[] = [];

  get buttonText(): string {
    return this.isLoading
      ? 'Logging in...'
      : this.overrideConnectText
      ? this.overrideConnectText
      : 'Connect Wallet';
  }

  /**
   * This is the default browser wallet
   */
  get defaultBrowserWallet(): BrowserWallet {
    if (EthereumProvider.browserProvider()) {
      // should return browser provider if available
      const abstractProvider = EthereumProvider.browserProvider() as AbstractProvider;
      const browserWalletConfig = this.getBrowserWallet(
        abstractProvider.provider,
        EthereumProvider.chainId()
      );
      if (browserWalletConfig) {
        return browserWalletConfig;
      }
    }

    const userAgentName = this.getUserAgentName();
    if (userAgentName) {
      return {
        name: userAgentName,
        logo: this.getUserAgentLogo(),
        network: EthereumProvider.chainId()
      };
    }

    // Default to metamask
    return {
      name: 'MetaMask',
      logo: require('@/assets/images/metamask.svg'),
      network: EthereumProvider.chainId()
    };
  }

  async computeAllBrowserWallets(): Promise<void> {
    const wallets: BrowserWallet[] = [];
    const walletNames: Set<string> = new Set();

    if (EthereumProvider.browserProvider()) {
      const defaultBrowserProvider = EthereumProvider.browserProvider() as AbstractProvider;
      const defaultBrowserWalletConfig = await this.getBrowserWalletConfigWithNetwork(
        defaultBrowserProvider.provider
      );
      if (defaultBrowserWalletConfig) {
        wallets.push(defaultBrowserWalletConfig);
      }
      walletNames.add(this.defaultBrowserWallet.name);
      const windowEthereum = window.ethereum as InjectedProvider;
      if (windowEthereum?.providers) {
        for (const provider of windowEthereum.providers) {
          const browserWalletConfig = await this.getBrowserWalletConfigWithNetwork(provider);
          if (browserWalletConfig && !walletNames.has(browserWalletConfig.name)) {
            wallets.push(browserWalletConfig);
          }
        }
      }
    }

    this.browserWallets = wallets;
  }

  getBrowserWallet(provider?: InjectedProvider, network = 1): BrowserWallet | undefined {
    if (!provider || !network) {
      return undefined;
    }

    const name = this.getBrowserWalletName(provider);
    if (!name || this.walletsHidden.has(name.toLowerCase())) {
      return undefined;
    }

    return {
      name,
      network,
      provider: markRaw(provider),
      logo: this.getBrowserWalletLogo(name)
    };
  }

  async getBrowserWalletConfigWithNetwork(
    provider: InjectedProvider
  ): Promise<BrowserWallet | undefined> {
    return this.getBrowserWallet(provider, (await new Web3Provider(provider).getNetwork()).chainId);
  }

  getBrowserWalletName(provider: InjectedProvider): string | undefined {
    if (provider.isCoinbaseWallet) {
      return BrowserWallets.CoinbaseWallet;
    } else if (provider.isBraveWallet) {
      return BrowserWallets.Brave;
    } else if (provider.isLedgerConnect) {
      return BrowserWallets.LedgerConnect;
    } else if (provider.isBitski) {
      return BrowserWallets.Bitski;
    } else if (provider.isMetaMask) {
      return BrowserWallets.MetaMask;
    }
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getBrowserWalletLogo(providerName: string) {
    if (providerName) {
      try {
        try {
          return require(`@/assets/images/${providerName.replace(' ', '_').toLowerCase()}.svg`);
        } catch {
          return this.getUserAgentLogo();
        }
      } catch {
        return undefined;
      }
    }
  }

  getUserAgentName(): string | undefined {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/edg/i)) {
      return 'Edge';
    } else if (userAgent.match(/firefox|fxios/i)) {
      return 'Firefox';
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
      return 'Chrome';
    } else if (userAgent.match(/safari/i)) {
      return 'Safari';
    } else if (userAgent.match(/opr\//i)) {
      return 'Opera';
    }
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getUserAgentLogo() {
    const userAgentName = this.getUserAgentName();
    if (userAgentName) {
      try {
        return require(`@/assets/images/${userAgentName.toLowerCase()}.svg`);
      } catch {
        // No logo
      }
    }
    return undefined;
  }

  getBrowserWalletProvider(name: string): InjectedProvider | undefined {
    const abstractProvider = EthereumProvider.browserProvider() as AbstractProvider;
    if (!abstractProvider) {
      return undefined;
    }

    if (abstractProvider.provider.providers) {
      let fn;
      if (name === BrowserWallets.MetaMask) {
        fn = (p: InjectedProvider) => !!p.isMetaMask && !p.overrideIsMetaMask;
      } else if (name === BrowserWallets.Bitski) {
        fn = (p: InjectedProvider) => !!p.isBitski;
      } else if (name === BrowserWallets.LedgerConnect) {
        fn = (p: InjectedProvider) => !!p.isLedgerConnect;
      } else if (name === BrowserWallets.Brave) {
        fn = (p: InjectedProvider) => !!p.isBraveWallet;
      } else if (name === BrowserWallets.CoinbaseWallet) {
        fn = (p: InjectedProvider) => !!p.isCoinbaseWallet;
      }

      if (fn) {
        return abstractProvider.provider.providers.find(fn);
      }
    }

    return abstractProvider.provider;
  }

  async created(): Promise<void> {
    window.manifold = {};
    // Set up the wallets hidden state
    this.multiHidden.split(',').forEach((wallet: string) => {
      this.walletsHidden.add(wallet.trim().toLowerCase());
    });

    // Get address by pulling information from EthereumProvider, because the widget may be created
    // AFTER the event is fired
    const address = EthereumProvider.selectedAddress();

    if (address) {
      // It means we connected before creating this widget
      this.providerAvailable = true;
      localStorage.setItem('connectedAddress', address);
      // Set up the wallet address state
      await this.onAddressChanged();
      await this.updateBalance();
    }

    window.addEventListener(ADDRESS_CHANGED, this.onAddressChanged);
    window.addEventListener(REAUTHENTICATE, this.onReauthenticate);
    // We set up the provider and chain listeners afterwards to avoid
    // situations where a provider change can trigger a
    // concurrent auto-reconnect with the above.
    window.addEventListener(PROVIDER_CHANGED, this.onProviderChanged);
    window.addEventListener(CHAIN_CHANGED, this.onChainChanged);

    await this.updateChainInfo();
  }

  destroyed(): void {
    window.removeEventListener(PROVIDER_CHANGED, this.onProviderChanged);
    window.removeEventListener(ADDRESS_CHANGED, this.onAddressChanged);
    window.removeEventListener(CHAIN_CHANGED, this.onChainChanged);
  }

  async mounted(): Promise<void> {
    if (!this.network.length && this.fallbackProvider.length) {
      this.badConfiguration = 'Config Error';
      throw new Error('fallbackProvider should not be configured on network agnostic connections.');
    } else {
      if (
        !!EthereumProvider.network() &&
        this.network.length === 1 &&
        this.network[0] !== EthereumProvider.network()
      ) {
        console.warn(
          'An older EthereumProvider was initialized with different inputs, your input for the current connect-widget will be ignored'
        );
      }
      /**
       * When initializing EthereumProvider, we only pass in network if we only want to support a single network.
       */
      const network = this.network.length === 1 ? this.network[0] : undefined;
      // Only pass in a mapping of fallbackProvider if it is multiple networks
      const fallbackHost = network ? this.fallbackProvider[0] : this._getRpcMapping();
      if (this.parentFrameUrl) {
        // In iframe, we need to detect app bridge provider first
        let appBridgeProvider;
        console.debug(
          `Parent frame URL configured to '${this.parentFrameUrl}', using app bridge provider`
        );
        try {
          appBridgeProvider = await detectProvider(this.parentFrameUrl);
        } catch (e) {
          console.debug('Error detecting app bridge provider:', e);
        }
        if (appBridgeProvider) {
          await EthereumProvider.initialize({
            network,
            fallbackHost,
            browserProviderOverride: appBridgeProvider,
            browserProviderIgnoreDisconnect: this.browserProviderIgnoreDisconnect
          });
        } else {
          // fallback to regular in-frame provider if app bridge cannot be established
          console.warn('No app bridge provider available');
          await EthereumProvider.initialize({
            network,
            fallbackHost,
            browserProviderIgnoreDisconnect: this.browserProviderIgnoreDisconnect
          });
        }
      } else {
        await EthereumProvider.initialize({
          network,
          fallbackHost,
          browserProviderIgnoreDisconnect: this.browserProviderIgnoreDisconnect
        });
      }
      await this._automaticallyReconnect();
      this._refreshBrowserWalletState();

      // whenever they complete a tx lets update the UX of their eth balance
      window.addEventListener('transactions-confirmed-event', async () => {
        await this.updateBalance();
      });
    }
  }

  /**
   * Handles button triggered connect
   */
  async connectDefaultBrowserWallet(_event: Event): Promise<void> {
    try {
      this.isLoading = true;
      await this._connectWithEthereumProvider(
        false,
        this.getBrowserWallet(this.getBrowserWalletProvider(this.defaultBrowserWallet.name))
      );
    } catch (error) {
      // Force disconnect (no need to disconnect provider as it failed to connect)
      this._disconnect(true, true);
    }
  }

  /**
   * Connects to web3 and updates all chain/wallet related info upon success.
   */
  async _connectWithEthereumProvider(
    autoReconnect = false,
    broswerWalletOverride?: BrowserWallet
  ): Promise<void> {
    try {
      await EthereumProvider.connect(broswerWalletOverride?.provider);
      if (broswerWalletOverride?.name === BrowserWallets.CoinbaseWallet) {
        await this.switchNetwork();
      }
    } catch (error) {
      if (!autoReconnect) {
        const transactionErrors = web3TransactionErrorHandling(error as TransactionError);
        switch (transactionErrors) {
          case TransactionErrors.REJECTED: {
            // Force disconnect (no need to disconnect provider as it failed to connect)
            this._disconnect(true, true);
            break;
          }
          case TransactionErrors.LEDGER_ERROR: {
            // Force disconnect (no need to disconnect provider as it failed to connect)
            this._disconnect(true, true);
            break;
          }
          case TransactionErrors.PENDING: {
            alert(`Please open ${this.defaultBrowserWallet.name} Wallet to continue.`);
            break;
          }
          default: {
            alert(
              `Could not connect to ${this.defaultBrowserWallet.name}, please try refreshing your page. If you continue to have issues, try closing your browser and re-opening it.`
            );
            break;
          }
        }

        throw error;
      }
    }
  }

  /**
   * Disconnects from web3 and deletes our Oauth cookie for the JSON API.
   */
  disconnectWallet(): void {
    this._disconnect();
  }

  /**
   * Disconnects from web3
   *
   * @param skipProviderDisconnect  - Do not disconnect the provider.
   * @param force                   - Force disconnect and reset even if there is no connected wallet
   */
  _disconnect(skipProviderDisconnect = false, force = false): void {
    this._disconnectBase(skipProviderDisconnect, force);
  }

  /**
   * This function should be called by any mixin that overrides _disconnect
   *
   * @param skipProviderDisconnect  - Do not disconnect the provider.
   * @param force                   - Force disconnect and reset even if there is no connected wallet
   */
  _disconnectBase(skipProviderDisconnect = false, force = true): void {
    if (this.walletConnected || force) {
      localStorage.removeItem('connectedAddress');
      this.walletAddressFull = undefined;
      this.walletAddressShort = undefined;
      this.walletENS = undefined;
      this.walletBalance = undefined;
      this.walletConnected = false;
      window.manifold = {
        isAuthenticated: false,
        address: '',
        dataClient: undefined,
        oauthToken: undefined
      };
      this.isLoading = false;
      if (!skipProviderDisconnect) {
        EthereumProvider.disconnect();
      }
    }
  }

  /**
   * Connects to web3 only if this.automaticallyReconnect is set and we
   * are already connected with a valid wallet address.
   */
  async _automaticallyReconnect(): Promise<void> {
    // Reconnect if
    // 1. autoReconnect is set
    // 2. we have a connected address in local storage
    // 3. we have a provider (only available if no network or network is correct
    if (
      this.autoReconnect &&
      localStorage.getItem('connectedAddress') &&
      EthereumProvider.provider()
    ) {
      // Provider only available if no network or network is correct
      if (!EthereumProvider.selectedAddress()) {
        // No address and address listener setup
        // Run auto-reconnect, which will trigger the address
        // changed callback and set up the appropriate state
        await this._connectWithEthereumProvider(true);
      }
    }
  }

  /**
   * Updates the current eth balance of the wallet being displayed.
   * Call this whenever the adddress, chain, or provider is changed.
   */
  async updateBalance(): Promise<void> {
    const provider = EthereumProvider.provider();
    try {
      if (this.walletAddressFull && provider) {
        const balanceString = (await provider.getBalance(this.walletAddressFull)).toString();
        const ethValue = formatEther(balanceString);
        const with3Decimals = ethValue.match(/^-?\d+(?:\.\d{0,3})?/);
        // with3Decimals looks like this (does not round!): [ '0.017', index: 0, input: '0.017926361227063654', groups: undefined ]
        if (with3Decimals && with3Decimals.length > 0) {
          this.walletBalance = with3Decimals[0];
        } else {
          this.walletBalance = undefined;
        }
      } else {
        this.walletBalance = undefined;
      }
    } catch (e) {
      // Error getting wallet balance
      console.warn(`Error getting wallet balance`, e);
    }
  }

  /**
   * Updates the name and corresponding color for chainInfo. Call this
   * whenever the chain information may have updated.
   */
  async updateChainInfo(): Promise<void> {
    /* NOTE possibility that there is duplicate functionality here
     * between this method and badConfiguration variable.
     */
    const chainId: number | undefined = EthereumProvider.chainId();
    if (this.network.length) {
      this.wrongChain =
        !chainId || !this.network.map((network) => network.valueOf()).includes(chainId);
    }
    if (chainId) {
      this.chainInfo = {
        name: EthereumNetwork[chainId],
        color: ETHEREUM_NETWORK_COLORS[chainId]
      };
      this.providerAvailable = true;
    } else {
      // No network provider or valid provider for specified network.
      this.chainInfo = null;
      this.providerAvailable = false;
    }
  }

  /**
   * Fires when reauthenication is requested again
   */
  async onReauthenticate(): Promise<void> {
    await this._authenticate(true);
  }

  /**
   * Fires when the address is changed
   */
  async onAddressChanged(): Promise<void> {
    await this._authenticate();
  }

  /**
   * Authentication helper
   *
   * First it updates all chainInfo.
   * Then it updates everything related to the wallet adddress and ens name
   * if possible. Finally it stores the wallet address as "connectAddress"
   * inside of local storage. If ther ewas an issue or the address is now
   * undefined/null, we clear all wallet related vlaues and clear localStorage
   * of the "connectedAddress" itme.
   */
  async _authenticate(force = false): Promise<void> {
    this.badConfiguration = null;
    this.updateChainInfo();
    const address = EthereumProvider.selectedAddress();
    const ens = EthereumProvider.selectedENSName();
    if (force || address !== this.walletAddressFull || ens !== this.walletENS) {
      // Reset current state via disconnect (no need to disconnect the provider or force disconnect)
      this._disconnect(true);

      this.walletAddressFull = address;
      this.walletENS = ens;
      if (address) {
        try {
          const addressLength = address.length;
          const retval =
            address.slice(0, 6) + '...' + address.slice(addressLength - 4, addressLength);
          this.walletAddressShort = retval;
          this.walletConnected = true;
          localStorage.setItem('connectedAddress', address);
        } catch (error) {
          const transactionErrors = web3TransactionErrorHandling(error as TransactionError);
          switch (transactionErrors) {
            case TransactionErrors.REJECTED: {
              // Force disconnect (no need to disconnect provider)
              this._disconnect(true, true);
              break;
            }
            case TransactionErrors.LEDGER_ERROR: {
              // Force disconnect (no need to disconnect provider)
              this._disconnect(true, true);
              break;
            }
            case TransactionErrors.PENDING: {
              alert(`Please open ${this.defaultBrowserWallet.name} Wallet to continue.`);
              break;
            }
            default: {
              alert('There was an issue with that wallet connection');
              break;
            }
          }
        }
      } else {
        // No address, no state
        this._disconnect(true);
      }
      this.isLoading = false;
    }
  }

  /**
   * Fires when the chain is changed
   * Ensures the chainInfo is updated then updates the balance we see.
   */
  async onChainChanged(): Promise<void> {
    this.badConfiguration = null;

    // There is a case where the auto-reconnect didn't work on initialization
    // because the provider was not available yet.  This will cause a chain change
    // event once it becomes available, so try auto-connection when this happens
    await this._automaticallyReconnect();
    this._refreshBrowserWalletState();
    await this.updateBalance();
  }

  /**
   * Fires when the provider is changed
   * Ensures that the chainInfo is updated then automatically reconnnects.
   */
  async onProviderChanged(): Promise<void> {
    this.badConfiguration = null;
    this.walletAvailable = !!(window && window.ethereum);
    this._refreshBrowserWalletState();
    await this._automaticallyReconnect();
  }

  _refreshBrowserWalletState(): void {
    this.walletAvailable = !!(window && window.ethereum);
    this.updateChainInfo();
    this.computeAllBrowserWallets();
  }

  /*
   * Helpful in every view for when users need to install a wallet
   */
  openMetamaskLink(): void {
    window.open('https://metamask.io', '_blank');
  }

  /**
   * Switches wallet to the default chain
   */
  async switchNetwork(): Promise<void> {
    await EthereumProvider.switchToCorrectChain();
  }

  _getRpcMapping(): Record<EthereumNetwork, string> | undefined {
    if (!this.network.length) {
      return;
    }
    const chains = this.network;
    const providerURIs = this._getProviderURIs();
    return chains.reduce((acc, chain, currentIndex) => {
      acc[chain] = providerURIs[currentIndex];
      return acc;
    }, {} as Record<EthereumNetwork, string>);
  }

  _getProviderURIs(): string[] {
    if (!this.fallbackProvider.length) {
      return [];
    }
    return this.fallbackProvider.map((providerURI) => this._processURI(providerURI));
  }

  _processURI(uri: string): string {
    const infuraMatch = uri.match(/^(wss:\/\/)(.*\.infura.io)(\/ws)(\/v[0-9]+\/[0-9a-f]+)$/);
    if (infuraMatch) {
      return `https://${infuraMatch[2]}${infuraMatch[4]}`;
    }
    return uri.replace('wss://', 'https://').replace('ws://', 'http://');
  }
}
